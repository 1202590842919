.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  flex-wrap: wrap;
}
.imageContainer {
  width: 95%;
  padding: 12px;
  overflow: hidden;
}
.imageContainer img {
  width: 100%;
}
.title {
  font-family: var(--title-Font-Family);
  font-size: var(--large-Font-Size);
  text-align: center;
}
.desc {
  font-size: var(--normal-Font-Size);
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.icon {
  width: 2.2rem;
  margin-right: 10px;
}
.detailsWrapper
{
  background-color: rgba(248, 248, 248,0.25);
  padding: 12px;
  border-radius: 16px;

}
.articleRoot {
  padding: 16px;
  margin: 2px 0;
}
.articleHeader {
  font-family: var(--title-Font-Family);
  margin-bottom: 5px;
}
.contactMethod {
  color: gray;
  font-size: var(--small-Font-Size);
  line-height: 1.5rem;
}
.socialMediaContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialMediaContainer .iconwrapper:nth-child(1) img:hover {
  border: 2px solid rgb(30, 156, 240);
}
.socialMediaContainer .iconwrapper:nth-child(2) img:hover {
  border: 2px solid rgb(197, 68, 248);
}
.iconwrapper {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iconwrapper img {
  width: 4rem;
  padding: 0.5rem;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border 200ms;
}
.iconwrapper p {
  color: gray;
  font-size: var(--small-Font-Size);
  line-height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .wrapper {
    flex-direction: initial;
    flex-wrap: initial;
    align-items: center;
    min-height: 15vh;
    padding: 1vh 8vw;
  }
}
