.search {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 25px;
  padding: 3px;
  outline: none;
  border: 3px solid #00b4cc;
}

.searchform {
  width: 100%;
}

.searchTerm {
  width: 100%;
  border: none;
  padding: 5px;
  height: 35px;
  border-radius: 10px 0 0 10px;
  outline: none;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  text-align: center;
  color: #fff;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer;
  font-size: 20px;
}
@media screen and (max-width: 1200px) {
  .search {
    margin: auto;
    width: 100%;
  }
}
/*Resize the wrap to see the search bar change!*/
