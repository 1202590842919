.question {
  justify-content: center;
  margin: auto;
  padding: 20px;
  margin: 10px;
}
.question img {
  width: 50%;
  height: 50%;
  margin-left: 50%;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.cardheader {
  background-color: white;
  width: 98%;
  padding: 5px;
  margin: 15px;
  border-bottom: none;
}
.item {
  margin: 30px;
  box-shadow: 1px 1px 1px #7ed1cf;
  border-radius: 12px;
  border: 1px solid #7ed1cf;
  transition: box-shadow 300ms;
}
.item:hover {
  box-shadow: 1px 1px 10px 2px #ebebeb;
}
.read-more-button {
  margin-top: 8px;
}
.link {
  text-decoration: none;
  color: black;
}

.bg-2 {
  margin-left: 83%;
  background-color: #28bcc0;
  width: 17%;
  border-radius: 12px;
  padding: 7px 4px;
  font-size: 0.9rem;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.display-text-group {
  cursor: pointer;
}
.link {
  text-decoration: none;
}
.que {
  display: flex;
  justify-content: space-between;
  color: #28bcc0;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}
.read-more-button {
  color: #7ed1cf;
}
.displayed-text {
  font-family: "Josfin Sans";
}
.head1 {
  width: 98%;
  font-family: "ProximaNova", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 20px;
}
.bg {
  background-color: #28bcc0;
  border-radius: 15px;
  color: ghostwhite;
}
@media (max-width: 1200px) {
  .que {
    flex-direction: column;
    font-size: 1.3rem;
  }
  .bg {
    justify-content: baseline;
    white-space: normal;
    margin: 10px 0px;
    padding: 5px 3px;
    font-size: 0.8rem;
    width: 150px;
  }
  .bg-2{
    margin-left: 54%;
    font-size: 0.8rem;
    white-space: normal;
    width: 46%;
    padding: 5px;
}

  .cardheader {
    width: 95%;
    padding: 3px;
    margin: 12px;
  }
  .display-text-group {
    font-size: 0.9rem;
  }
}
