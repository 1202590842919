.termsection {
  animation: TermAnimate 700ms;
  max-width: 94%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
}
.termhead {
  display: flex;
  font-family: var(--title-Font-Family);
}
.termheading {
  font-weight: 900;
  font-size: var(--large-Font-Size);

  margin-bottom: 2.5rem;
}
.updatedate {
  color: #ba2afd;
  font-weight: bold;
}
.termcontent {
  font-size: var(--mini-Font-Size);
}
.termsection p {
  margin-top: 0.5rem;
  line-height: 2.5rem;
  text-align: justify;
}

.termcontent h1 {
  font-size: var(--medium-Font-Size);
  font-weight: 700;
  margin-top: 2.5rem;
}

.termsection {
  padding-top: 3rem;
  padding-bottom: 6rem;
}
.termcontent ul {
  list-style-type: disc;
  list-style-position: inside;
}
.termcontent ul li {
  margin: 1rem 1rem;
  line-height: 2rem;
  text-align: justify;
}
@keyframes TermAnimate {
  0% {
    transform: translate(300%, 0);
  }

  100% {
    transform: translate(0%, 0);
  }
}
@media (max-width: 1200px) {
  .termsection {
    width: 85%;
  }
  .termsection p {
    line-height: 1.9;
  }

  .termcontent ul {
    padding-left: 2px;
  }
}
