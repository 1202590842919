.root {
  width: 95%;
  max-width: 1200px;
  margin: auto;
  padding: 2rem 1rem;
  margin: 12px 0;
  box-shadow: 1px 1px 5px 1px #e6e6e6;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 300ms;
}
.root:hover {
  background-color: #f1f1f11a;
}
.title {
  font-family: var(--title-Font-Family);
  font-weight: 400;
}
.category {
  color: gray;
  font-size: var(--small-Font-Size);
  margin-top: 16px;
}
.author {
  margin-top: 0.5rem;
  color: gray;
  font-size: var(--small-Font-Size);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.chip {
  padding: 5px 1rem;
  border-radius: 5px;
  font-size: 0.7rem;
  font-weight: bold;
  background-color: rgb(196, 250, 250);
  margin: 0 5px;
  color: rgb(66, 66, 66);
}
@media screen and (min-width: 1200px) {
  .chip {
    font-size: 0.8rem;
  }
}
