.root {
  background-color: #141414;
  color: white;
  padding: 20px 10px;
}

.copyright {
  color: rgb(187, 187, 187);
  font-size: var(--small-Font-Size);
  text-align: center;
}
