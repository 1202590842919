.root {
  background-color: white;
  overflow-x: hidden;
}
.bodyWrapper {
  width: 95%;
  max-width: 1560px;
  margin: auto;
}
@media (min-width: 1200px) {
  .mobilesearch {
    display: none;
  }
}
