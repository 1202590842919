.root {
  width: 100%;
  background: transparent;
}
.blogsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.title {
  font-size: var(--large-Font-Size);
  font-family: var(--title-Font-Family);

}
.spinwrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16vmin;
}
.titlewrapper
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1430px;
  margin: auto;
  padding: 2rem;
}