.privacysection {
  animation: privAnimate 700ms;
  max-width: 94%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
}
.privacyhead {
  display: flex;
  font-family: var(--title-Font-Family);
  color: var(--text-primary-Color);
}
.privacyheading {
  font-weight: 800;
  font-size: 3rem;
  margin-bottom: 2.5rem;
}
.privacycontent {
  font-size: var(--mini-Font-Size);
  line-height: 1.3rem;
}
.privacysection p {
  margin-top: 0.5rem;
  line-height: 2rem;
  text-align: justify;
}

.privacycontent h1 {
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 1.5;
  margin-top: 2.5rem;
}
.privacycontent h1::before {
  content: ">";
  margin-right: 1rem;
}
.privacysection {
  padding-top: 3rem;
  padding-bottom: 6rem;
}

.updatedate {
  color: #ba2afd;
  font-weight: bold;
}
.privacycontent ul li {
  margin: 1rem 0;
  margin-left: 1rem;
}
@keyframes privAnimate {
  0% {
    transform: translate(300%, 0);
  }

  100% {
    transform: translate(0%, 0);
  }
}
@media (max-width: 1200px) {
  .privacysection {
    width: 85%;
  }
  .privacysection p {
    line-height: 1.9;
  }
  .privacyheading {
    font-size: 2.2rem;
  }
  .privacycontent h1 {
    font-size: 1.5rem;
  }
  .privacycontent {
    font-size: 1rem;
  }
}
