.root {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  flex-wrap: wrap;
  flex-direction: column;
}
.logo {
  width: 50%;
  max-width: 128px;
}

.signupForm {
  padding: 10px;
  width: 100%;
  max-width: 425px;
}
.signupForm label {
  display: block;
  font-size: var(--small-Font-Size);
  font-weight: bold;
}
.signupForm input {
  width: 100%;
  margin: 12px 0;
  padding: 10px 1rem;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  outline-width: 0;
  background-color: #f9f9f9;
  font-size: var(--small-Font-Size);
}
.signupForm input[type="submit"] {
  background-color: var(--primary-color);
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.title {
  font-size: var(--large-Font-Size);
  font-weight: bold;
  font-family: var(--title-Font-Family);
  margin: 2rem 0;
}
.error {
  min-height: 2rem;
  color: red;
}
