.wrapper
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 50vh;
    padding: 2rem;
}
.space
{
    min-height: 2rem;
}
.title{
    font-family: var(--title-Font-Family);
   
}