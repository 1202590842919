.root {
  background-color: rgb(253, 253, 253);
}
.title {
  font-size: var(--large-Font-Size);
  font-family: var(--title-Font-Family);
  margin: 2.5rem;
  margin-top: 1rem;
  margin-left: 5%;
}
.quillWrapper {
  width: 80%;
  margin: auto;
  height: 50vh;
  max-height: 70vh;
}

.blogTitleWrapper {
  margin: auto;
  width: 80%;
  margin-bottom: 1rem;
  margin-top: 0.1rem;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: flex-end;
}

.blogTitleInput {
  width: 80%;
  border: 0;
  border-bottom: 1px solid rgb(180, 180, 180);
  padding: 1rem 1rem;
  font-size: var(--medium-Font-Size);
  outline-width: 0;
  color: #141414;
  background-color: inherit;
}
.blogCatInput {
  font-size: var(--normal-Font-Size);
  max-width: 580px;
}
.fileInput {
  display: none;
}
.blogPlaceholder {
  font-size: var(--medium-Font-Size);
  color: gray;
}
.uploadBtn {
  padding: 0.7rem 2rem;
  margin-left: 5%;
  border: 0;
  border-radius: 8px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  font-weight: bold;
  transition: all 300ms;
  box-shadow: 1px 1px 3px 1px rgb(216, 216, 216);
}
