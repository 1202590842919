.root {
  width: 100%;
  max-width: 1366px;
  padding: 10px;
  margin: auto;
}
.wrapper {
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  margin-left: 2rem;
}
.title {
  font-size: var(--large-Font-Size);
}
.category {
  background-color: rgb(1, 189, 64);
  width: fit-content;
  width: -moz-fit-content;
  font-size: var(--small-Font-Size);
  padding: 5px 16px;
  border-radius: 18px;
  margin-top: 1rem;
  color: white;
  font-weight: bold;
}
.timestamp {
  width: fit-content;
  width: -moz-fit-content;
  color: rgb(138, 135, 135);
  font-style: italic;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 1.5rem;
  text-align: left;
}

.body {
  border: 0;
  margin-top: 3rem;
  width: 100%;
  overflow-wrap: break-word;
  text-align: justify;
  height: 100vh;
}
