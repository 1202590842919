.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background-color: transparent;
  z-index: 1;
}
.back {
  width: 48vmax;
  height: 48vmax;
  max-width: 680px;
  max-height: 680px;
  background-color: rgba(255, 155, 155, 0.2);
  border-radius: 50%;
  position: absolute;
  z-index: 0;
}
.mockupWrapper {
  width: 200px;
  max-width: 425px;
  animation: mockupAnimate 1000ms;
  z-index: 1;
}
.mockupWrapper img {
  width: 100%;
}

.heroTextWrapper {
  margin-top: 5vh;
  z-index: 1;
}
.heroTitle {
  font-family: var(--title-Font-Family);
  font-size: var(--medium-Font-Size);
  font-weight: bold;
  text-align: justify;
  line-height: 2.5rem;
}

.heroDescription {
  color: gray;
  line-height: 32px;
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.linkButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.storeLinkButton {
  background-color: #141414;
  color: white;
  display: flex;
  text-decoration: none;
  padding: 0.5rem;
  margin: 8px 1rem;
  border-radius: 5px;
}
.storeLinkButton:nth-child(1) img {
  width: 2rem;
  height: 3rem;
}
.storeLinkButton img {
  width: 2.5rem;
  height: 3rem;
  margin-right: 8px;
}
.storeLinkButton p {
  font-weight: bold;
  font-size: var(--normal-Font-Size);
}
.storeLinkButton p span {
  font-weight: 400;
}
@keyframes mockupAnimate {
  0% {
    transform: translate(400%, 0);
  }

  100% {
    transform: translate(0%, 0);
  }
}

@media screen and (min-width: 1200px) {
  .root {
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }
  .heroTextWrapper {
    width: 450px;
  }
  .heroTitle {
    font-weight: 900;
    font-size: var(--large-Font-Size);
    letter-spacing: 0.5px;
    line-height: 56px;
  }
  .back{
    height: 60vmax;
    width: 60vmax;
  }
  .mockupWrapper {
    width: 360px;
  }
}
