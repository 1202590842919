@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Nunito+Sans:wght@400;600;700;900&display=swap");
:root {
  --primary-color: #28bcc0;
  --primary-light-color: #7ed1cf;

  --secondary-color: #fcca53;

  --primary-Text-Color: #535353;

  --largest-Font-Size: 3rem;
  --large-Font-Size: 2.1rem;

  --medium-Font-Size: 1.9rem;

  --normal-Font-Size: 1.1rem;

  --small-Font-Size: 0.9rem;

  --title-Font-Family: "Nunito Sans", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (min-width: 1200px) {
  :root {
    --largest-Font-Size: 3rem;
    --large-Font-Size: 2.25rem;

    --medium-Font-Size: 2.05rem;

    --normal-Font-Size: 1.1rem;

    --small-Font-Size: 0.9rem;

    --title-Font-Family: "Nunito Sans", sans-serif;
  }
}
