.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.title {
  font-family: var(--title-Font-Family);
}
.title span {
  color: var(--primary-color);
}

.cardContainers {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.gap {
  height: 1rem;
}
@media screen and (min-width: 768px) {
  .title {
    font-size: var(--medium-Font-Size);
  }
}
@media screen and (min-width: 1200px) {
  .title {
    margin-top: 5vh;
    font-size: var(--large-Font-Size);
  }
}
