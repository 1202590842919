.all{
  max-width: 94%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}
.abouthead {
  display: flex;
  font-family: var(--title-Font-Family);
  color: var(--text-primary-Color);
}
.q {
  color: #28bcc0;
}

.img {
  display: flex;
}

.abt{
  font-weight: 800;
  font-size:var(--largest-Font-Size);
  margin-bottom: 1.5rem;

}

.allcontent{
  box-sizing: border-box;
  margin-top: 20px;
  width: 100%;
  margin-top: 30px;
  animation: colmAnimate 700ms;
}
.descrip {
  font-size: var(--normal-Font-Size);
  margin-right: 30px;
  line-height: 30px;
}
.descrip ul {
  list-style-type: disc;
  list-style-position: inside;
}
.descrip ul li {
  padding: 10px;
}
.textdown{
  text-align: center;
  margin-top:10px;
  font-size: 2.5rem
}
@keyframes colmAnimate {
  0% {
    transform: translate(300%, 0);
  }

  100% {
    transform: translate(0%, 0);
  }
}
@media (max-width: 1200px) {

  .img img {
    width: 60%;
  }
  .abt{
    font-size: 2rem;
  }
  .headline {
    line-height: 25px;
  }

  .allcontent{
    margin-left: 10px;
  }
  .img {
    justify-content: center;
  }
  .descrip {
    font-size: 1rem;
    line-height: 25px;
    margin-right: 10px;
  }
  .descrip ul li {
    padding: 5px;
    
  }
  .textdown{
    font-size: 1.5rem;
  }
}
