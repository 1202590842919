.root {
  border-radius: 8px;
  padding:16px;
  width: 320px;
  box-shadow: 1px 1px 15px 1px #ececec;
  margin: 1rem;
 position: relative;
 overflow: hidden;
 z-index: 2;
}
.over{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(109, 40, 217,0.4);
  clip-path: circle(18.9% at 89% 90%);
  z-index: -1;
}
.featureImage {
  display: none;
  width: 280px;
}
.title {
  width: 100%;
  word-spacing: 5px;
  font-size: 1.2rem;
  line-height: 30px;
  font-family: var(--title-Font-Family);
}
.description {
  font-size: var(--small-Font-Size);
  word-spacing: 10px;
  text-align: justify;
  line-height: 26px;
  margin-top: 12px;
  color: #474747;
}

@media screen and (min-width: 768px) {
  .root {
    background: inherit;
    box-shadow: none;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
    padding: 5px;
    justify-content: space-evenly;
    max-width: auto;
  }
  .over{
    display: none;
  }
  .featureImage {
    display: block;
  }
  .textWrapper {
    margin-left: 4rem;
    box-shadow: 0.5px 0.5px 5px 1px #ebebeb;
    padding: 20px;
    border-radius: 10px;
    transform: translate(-15%, 0);
  
  }
  .inverse {
    flex-direction: row-reverse;
  }
  .inverse .textWrapper {
    margin-left: 0;
    margin-right: 4rem;
    transform: translate(15%, 0);
  }
}
@media screen and (min-width: 1200px) {
  .featureImage {
    width: 580px;
  }
  .title {
    color: var(--text-primary-Color);
  }
  .textWrapper {
    width: 560px;
  }
}
