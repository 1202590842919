.root {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  z-index: 15;
}
.menuButton {
  border: 0;
  outline: 0;
  background-color: inherit;
  padding: 10px;
  cursor: pointer;
}
.menuButton img {
  width: 32px;
}
.navigationMenus {
  width: 0;
  max-width: 425px;
  height: 100vh;
  background-color: white;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: all 500ms;
}
.menuCloseIcon {
  margin-left: auto;
  margin-right: 5vw;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  outline-width: 0;
  border: 0;
  background-color: inherit;
  transition: all 300ms;
  cursor: pointer;
}
.menuCloseIcon:hover {
  background-color: rgba(221, 221, 221, 0.9);
}
.resMenuLogo {
  width: 64px;
  margin-bottom: 16px;
}
.navigationMenuVisible {
  width: 60vw;
}
.navigationMenus ul {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navigationMenus ul li {
  list-style-type: none;
  padding: 1.5vh 2px;
  margin-top: 1rem;
  border-bottom: 2px solid transparent;
}
.navigationMenus ul li:hover {
  border-bottom: 2px solid rgba(141, 141, 141, 0.9);
}
.navigationMenus ul li a {
  text-decoration: none;
  color: #141414;
}
.companyLogoTitleWrapper {
  display: flex;
  align-items: center;
  margin: auto;
}
.companyLogoTitleWrapper img {
  width: 48px;
}
.companyLogoTitleWrapper h1 {
  margin-left: 12px;
}
.loginMenu {
  font-weight: bold;
  border: 2px solid var(--primary-color);
  padding-top: 5px;
  padding-bottom: 10px;
}
.loginMenu:hover {
  color: rgb(175, 55, 245);
}
.signUpMenu {
  background-color: var(--primary-color);
  padding: 1rem 2rem;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  font-size: var(--normal-Font-Size);
}
.logOutBtn {
  padding: 14px 0;
  width: 30vw;
  max-width: 320px;
  font-size: var(--mini-Font-Size);
  background-color: var(--secondary-color);
  border: 0;
  border-radius: 8px;
  cursor: pointer;
}
@media screen and (max-width: 1386px) {
  .mobilesearch {
    width: 90%;
  }
}
@media screen and (min-width: 1368px) {
  .root {   
    position: sticky;
    top: 0;
    background-color: white;
  }
  .css-1orzy9s {
    padding-bottom: 25px;
  }
  .css-1iz4iln {
    padding-bottom: 25px;
  }
  .head {
    display: flex;
    flex-direction: column;
  }
  .companyLogoTitleWrapper {
    margin: 0;
  }
  .companyLogoTitleWrapper img {
    width: 4rem;
  }
  .navigationMenus {
    position: static;
    height: auto;
    margin-left: auto;
    width: auto;
    max-width: initial;
    background-color: transparent;
  }
  .navigationMenus ul {
    flex-direction: row;
  }
  .navigationMenus ul li {
    margin: 0 1.5rem;
    cursor: pointer;
    padding-bottom: 5px;
    transition: all 200ms;
  }
  .navigationMenus ul li:hover {
    color: rgba(141, 141, 141, 0.9);
  }
  .menuCloseIcon {
    display: none;
  }
  .menuButton {
    display: none;
  }
  .resMenuLogo {
    display: none;
  }
  .logOutBtn {
    padding: 14px 1.5rem;
    font-size: var(--mini-Font-Size);
    background-color: var(--secondary-color);
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    width: fit-content;
    width: -moz-fit-content;
  }
}
