.button {
  padding: 0.8rem 2rem;
  border-radius: 8px;
  background-color: var(--primary-color);
  outline-width: 0;
  border: 0;
  cursor: pointer;
  font-size: var(--normal-Font-Size);
  font-weight: bold;
  color: white;
  border: 1px solid var(--primary-color);
}
.button:hover{
  box-shadow: 0.5px 0.5px 5px 1px #e6e6e6;
}
.borderedButton {
  background-color: transparent;
  color: var(--primary-Text-Color);
  transition: all 300ms;
}
.borderedButton:hover {
  background-color: rgba(236, 236, 236, 0.2);
  box-shadow: none;
}
.small{
  padding: 0.8rem 1.2rem;
}
.secondaryBtn{
  background-color: var(--secondary-color);
  border: 0;
  color: #292929;
  font-weight: 600;
  font-size: var(--mini-Font-Size);
  transition: all 500ms;
}
