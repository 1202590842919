.item1{
    margin: 20px 70px;
    box-shadow: 1px 1px 1px #7ED1CF;
    border-radius: 12px;
    border: 1px solid #7ED1CF;
}
.card-img-top{
    padding: 10px;
    height: auto;
    width: 60%;

}
.text-center {
    text-align: center;
}
.image{
    display: flex;
    padding: 25px;
    max-height: 70vmax;
    justify-content: center;
    
}
.spinner-center
{
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 15vh 0;
}
.text{
    padding: 0px 25px ;
    
}
.text-area{
    font-size: 1.2em;
    margin-top: 5px;
}
.end{
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}
.que1{
    display: flex;
    color: #28BCC0;
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: bold;

}

.category{
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin: 30px 23px;
}
@media(max-width:1200px){
    .text-area{
        font-size: 1rem;
    }
    .que1{
        font-size: 1.2rem;
    }
    .category{
        font-size: 0.9rem;
    }
    .card-img-top{
        width: 100%;
        padding: 0px;
    
    }
    .image{
        padding: 20px;
    }
    .item1{
        margin: 15px;
    }
    .down{
        padding: 20px;
        flex-direction: row;
    }
    .storeLinkButton1{
        padding: 5px;
    }
    .storeLinkButton1 img{
        width: 2.2rem !important;
    }
    .storeLinkButton1 p {
        font-size: 13px !important;
    }
}
.down{
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    margin-top: 15px;
    justify-content: center;
    margin-bottom: 10px;
    color: #28BCC0;
}
.storeLinkButton1 {
    background-color: #141414;
    color: white;
    display: flex;
    text-decoration: none;
    padding: 0.5rem;
    margin: 8px 1rem;
    border-radius: 15px;
  }
  .storeLinkButton1:nth-child(2) img {
    width: 3rem;
  }
  .storeLinkButton1 img {
    width: 3rem;
    margin-right: 12px;
    margin-left: 4px;
  }
  .storeLinkButton1 p {
    font-weight: bold;
    font-size: var(--normal-Font-Size);
  }
  .storeLinkButton1 p span {
    font-weight: 400;
  }
  .linkButtonWrapper1 {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }